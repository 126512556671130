import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const LightModeSvg = ({ setDarkMode, darkMode }) => {
  const color = darkMode ? "rgb(235, 235, 235)" : "black";
  return (
    <button onClick={() => setDarkMode(false)}>
      <svg className="light-mode" width="20" height="20" viewBox="0 0 100 100">
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(50,10)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(78,23)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(90,50)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(80,78)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(50,90)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(10,50)"
        />
        <circle
          r="7"
          cx="0"
          cy="0"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(22,78)"
        />
        <circle
          r="25"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(50,50)"
        />
        <circle
          r="7"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(22,22)"
        />
      </svg>
    </button>
  );
};

const DarkModeSvg = ({ setDarkMode, darkMode }) => {
  const color = darkMode ? "rgb(235, 235, 235)" : "black";
  return (
    <button onClick={() => setDarkMode(true)}>
      <svg className="dark-mode" width="20" height="20" viewBox="0 0 100 100">
        <path
          d="M50 20A40 40 0 1 0 50 70 30 30 0 1 1 50 20z"
          stroke={color}
          strokeWidth="2"
          fill={color}
          transform="translate(30,7)"
        />
      </svg>
    </button>
  );
};

const RSSFeed = ({ darkMode }) => {
  const color = darkMode ? "rgb(235, 235, 235)" : "black";
  return (
    <div className="rss">
      <a
        href="https://charliegerard.dev/rss.xml"
        className="rss-icon"
        title="RSS"
      >
        <svg viewBox="0 0 450 450">
          <path
            d="M201.8 347.2c0 20.3-16.5 36.8-36.8 36.8 -20.3 0-36.8-16.5-36.8-36.8s16.5-36.8 36.8-36.8C185.3 310.4 201.8 326.8 201.8 347.2zM128.2 204.7v54.5c68.5 0.7 124 56.3 124.7 124.7h54.5C306.7 285.3 226.9 205.4 128.2 204.7zM128.2 166.6c57.9 0.3 112.3 22.9 153.2 63.9 41 41 63.7 95.5 63.9 153.5h54.5c-0.3-149.9-121.7-271.4-271.6-271.9V166.6L128.2 166.6z"
            fill={color}
          />
        </svg>
      </a>
    </div>
  );
};

const Header = ({ siteTitle }) => {
  const [darkMode, setDarkMode] = useState(false);

  // useEffect(() => {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     setDarkMode(true);
  //   } else {
  //     setDarkMode(false);
  //   }
  // }, []);

  useEffect(() => {
    const savedMode = localStorage.getItem("dark-mode");
    savedMode === "true" ? setDarkMode(true) : setDarkMode(false);
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (darkMode) {
      !body.classList.contains("dark") && body.classList.add("dark");
      localStorage.setItem("dark-mode", "true");
    } else {
      body.classList.contains("dark") && body.classList.remove("dark");
      localStorage.setItem("dark-mode", "false");
    }
  }, [darkMode]);

  return (
    <header>
      <ul>
        <li className="menu-link">
          <Link to="/">Charlie Gerard</Link>
        </li>
        <li className="menu-link">
          <Link to="/projects">Projects</Link>
        </li>
        <li className="menu-link">
          <Link to="/speaking">Speaking</Link>
        </li>
        <li className="menu-link">
          <Link to="/blog">Blog</Link>
        </li>
        {/* <li className="menu-link">
          <Link to="https://donate.stripe.com/5kA9Dh2eHccZfD25kk">Sponsor me!</Link>
        </li> */}
      </ul>
      <section className="icons">
        {!darkMode ? (
          <DarkModeSvg setDarkMode={setDarkMode} darkMode={darkMode} />
        ) : (
          <LightModeSvg setDarkMode={setDarkMode} darkMode={darkMode} />
        )}
        <RSSFeed darkMode={darkMode} />
      </section>
    </header>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
